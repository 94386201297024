<template>
    <div v-if="$can('access','PdfCodeVersion1')">
        <v-list-item class="font-sm" @click="viewPdf()" v-if="updateMode && listMode"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>
        <v-btn
            class="ml-3"
            small
            v-if="updateMode && !listMode"
            @click.stop="viewPdf()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
    </div>
    <div v-else>
        <v-btn
            :loading="loading.shippingInstruction"
            small
            class="ml-2 lighten-2"
            v-if="updateMode && !listMode"
            @click.stop="printShippingInstruction()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printShippingInstruction()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.shippingInstruction"/>
            <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
            <span>{{ $t('message.pdf') }}</span>
        </v-list-item>
        <PrintSdaBlob
            :sda-id="shippingInstructionId"
            :key="updateShippingInstructionKey"
            @pdf-uploading="shippingInstructionUploading"
            @pdf-uploaded="shippingInstructionUploaded"
            @pdf-not-uploaded="shippingInstructionNotUploaded"
            v-if="hasShippingInstruction"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";
import {v4 as uuidv4} from "uuid";

const PrintSdaBlob = () => import("Views/v1/sdas/PrintSdaBlob");
export default {
    name: "PrintShippingInstructionButton",
    props: ['shippingInstructionId','updateMode','listMode','shippingInstructionNumber'],
    components: {PrintSdaBlob},
    data() {
        return {
            currentShippingInstructionPdf: null,
            hasShippingInstruction: false,
            loading: {
                shippingInstruction: false,
            },
            updateShippingInstructionKey: 1
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/sdas/" + this.shippingInstructionId, {
                        params: {
                            fields: [
                                'sdas.location AS sdas__location'
                            ]
                        }
                    })
                    .then(response => {
                        const shippingInstruction = response.data.data[0]
                        if(shippingInstruction){
                            this.currentShippingInstructionPdf = shippingInstruction.Sda.location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        shippingInstructionNotUploaded() {
            this.loading.shippingInstruction = false
            this.hasShippingInstruction = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        shippingInstructionUploaded(url) {
            this.currentShippingInstructionPdf = url
            this.loading.shippingInstruction = false
            this.viewFile(this.currentShippingInstructionPdf)
            this.hasShippingInstruction = false
        },
        shippingInstructionUploading() {
            this.loading.shippingInstruction = true
        },
        printShippingInstruction() {
            this.loading.shippingInstruction = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentShippingInstructionPdf != null){
                        this.viewFile(this.currentShippingInstructionPdf)
                            .then(() => this.loading.shippingInstruction = false)
                            .catch(() => this.loading.shippingInstruction = false)
                    } else {
                        this.hasShippingInstruction = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.shippingInstruction = false
                })
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        },
        viewPdf () {
            const document = encodeURIComponent(this.shippingInstructionNumber + '_SI')
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/sdas/print/'
                + this.shippingInstructionId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
    }
}
</script>

<style scoped>

</style>